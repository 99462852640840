.figma-corner {
  position: relative;
}
.figma-corner:before {
  left: -3px;
  top: -3px;
}
.figma-corner:after {
  right: -3px;
  top: -3px;
}
.figma-corner::before,
.figma-corner::after,
.figma-corners-bottom::before,
.figma-corners-bottom::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #9a4bfe;
  outline: 2px solid #9a4bfe;
  border-radius: 1px;
  width: 6px;
  height: 6px;
}
.figma-corners-bottom {
  position: absolute;
  width: 100%;
}
.figma-corners-bottom:before {
  left: -12px;
  bottom: -48px;
}
.figma-corners-bottom:after {
  right: 3px;
  bottom: -48px;
}
