.installer-url {
  font-weight: 600;
  opacity: 0.5;
  text-decoration: underline;
  text-decoration-color: var(--primary);
  text-underline-position: from-font;
  margin-left: 0px;
  font-size: 1em;
  width: max-content;
}

.installer-url-holder {
  display: inline-flex;
  align-items: baseline;
}

.installer-edit-button {
  line-height: 1em;
  cursor: pointer;
  background: rgba(5, 5, 5, 0.1);
  padding: 0.1em 0.2em 0.2em 0.2em;
  border-radius: 5px;
  margin-left: 3px;
}

.installer-snippet {
  width: auto;
  padding: 0.25em 0.5em;
  border-radius: 3px;
  background: #fcff6f;
  color: rgb(0 0 0 / 50%);
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 500;
  font-family: monospace;
  letter-spacing: -0.25px;
}
.installer-src {
  font-family: monospace;
  color: #1f1f1f;
}
.installer-copy {
  font-family: monospace;
  position: absolute;
  bottom: -20px;
  right: 0px;
  font-weight: 600;
  cursor: pointer;
  padding: 0.1em 0.35em;
  font-size: 11px;
  color: white;
  border-radius: 3px;
  font-family: "Assistant";
  letter-spacing: normal;
}
