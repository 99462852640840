.side-panel-container {
  font-family: "Assistant", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  background: rgba(255, 255, 244, 1);
  border: 1px solid #f7f7e8;
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 5px;
  position: fixed;
  padding-left: 1em;
  left: 1vw;
  width: 25vw;
  top: 7.5vh;
  height: 85vh;
}
.side-panel-wide {
  width: 50vw;
}
h1 {
  font-size: 1.2em;
  font-weight: 400;
}
h3 {
  font-family: "Assistant";
  font-weight: 600;
  line-height: 10px;
  font-size: 1rem;
}
.letter-animate {
  animation: letterUp 500ms cubic-bezier(0, 0.2, 0.2, 1);
  will-change: opacity, transform;
}
@keyframes letterUp {
  0% {
    opacity: 0;
    transform: translateY(30px) rotate(3deg);
    transform-origin: top;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0px) rotate(0deg);
  }
}

.confirm-link {
  font-weight: 600;
  opacity: 0.5;
  cursor: pointer;
  padding: 0.1em 0.25em;
  font-size: 0.8em;
  background: rgb(252 223 66 / 20%);
  display: block;
  border-radius: 3px;
  width: fit-content;
  margin: 0.5em 0;
}
.sub-note {
  opacity: 0.66;
  font-size: 0.66em;
}
.google-button {
  height: 40px;
  width: 180px;
  margin: 1em 0;
  display: block;
  position: relative;
  animation: fadeIn 1000ms 400ms cubic-bezier(0, 0.2, 0.2, 1) both;
}

.fade-in img {
  filter: opacity(0.5) invert(0.5);
}
.fade-in-left {
  animation: fadeInLeft 1000ms 200ms cubic-bezier(0, 0.2, 0.2, 1) both;
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(30px) rotate(0deg);
    transform-origin: top;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.qJTHM {
  -webkit-user-select: none;
  color: #202124;
  direction: ltr;
  -webkit-touch-callout: none;
  font-family: "Roboto-Regular", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  -webkit-text-size-adjust: 100%;
}

.ynRLnc {
  left: -9999px;
  position: absolute;
  top: -9999px;
}

.L6cTce {
  display: none;
}

.bltWBb {
  word-break: break-all;
}

.hSRGPd {
  color: #1a73e8;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
}

.Bz112c-W3lGp {
  height: 16px;
  width: 16px;
}

.Bz112c-E3DyYd {
  height: 20px;
  width: 20px;
}

.Bz112c-r9oPif {
  height: 24px;
  width: 24px;
}

.Bz112c-uaxL4e {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.LgbsSe-Bz112c {
  display: block;
}

.S9gUrf-YoZ4jf,
.S9gUrf-YoZ4jf * {
  border: none;
  margin: 0;
  padding: 0;
}

.fFW7wc-ibnC6b > .aZ2wEe > div {
  border-color: #4285f4;
}

.P1ekSe-ZMv3u > div:nth-child(1) {
  background-color: #1a73e8 !important;
}

.P1ekSe-ZMv3u > div:nth-child(2),
.P1ekSe-ZMv3u > div:nth-child(3) {
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    linear-gradient(to right, #1a73e8, #1a73e8) !important;
}

.haAclf {
  display: inline-block;
}

.nsm7Bb-HzV7m-LgbsSe {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background-color 0.218s, border-color 0.218s;
  transition: background-color 0.218s, border-color 0.218s;
  -webkit-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dadce0;
  color: #3c4043;
  cursor: pointer;
  font-family: "Google Sans", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}

@media screen and (-ms-high-contrast: active) {
  .nsm7Bb-HzV7m-LgbsSe {
    border: 2px solid windowText;
    color: windowText;
  }
}

.nsm7Bb-HzV7m-LgbsSe.pSzOP-SxQuSe {
  font-size: 14px;
  height: 32px;
  letter-spacing: 0.25px;
  padding: 0 10px;
}

.nsm7Bb-HzV7m-LgbsSe.purZT-SxQuSe {
  font-size: 11px;
  height: 20px;
  letter-spacing: 0.3px;
  padding: 0 8px;
}

.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe {
  padding: 0;
  width: 40px;
}

.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe.pSzOP-SxQuSe {
  width: 32px;
}

.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe.purZT-SxQuSe {
  width: 20px;
}

.nsm7Bb-HzV7m-LgbsSe.JGcpL-RbRzK {
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.nsm7Bb-HzV7m-LgbsSe.JGcpL-RbRzK.pSzOP-SxQuSe {
  -webkit-border-radius: 16px;
  border-radius: 16px;
}

.nsm7Bb-HzV7m-LgbsSe.JGcpL-RbRzK.purZT-SxQuSe {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.nsm7Bb-HzV7m-LgbsSe.MFS4be-Ia7Qfc {
  border: none;
  color: #fff;
}

.nsm7Bb-HzV7m-LgbsSe.MFS4be-v3pZbf-Ia7Qfc {
  background-color: #1a73e8;
}

.nsm7Bb-HzV7m-LgbsSe.MFS4be-JaPV2b-Ia7Qfc {
  background-color: #202124;
  color: #e8eaed;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  height: 18px;
  margin-right: 8px;
  min-width: 18px;
  width: 18px;
}

.nsm7Bb-HzV7m-LgbsSe.pSzOP-SxQuSe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  height: 14px;
  min-width: 14px;
  width: 14px;
}

.nsm7Bb-HzV7m-LgbsSe.purZT-SxQuSe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  height: 10px;
  min-width: 10px;
  width: 10px;
}

.nsm7Bb-HzV7m-LgbsSe.jVeSEe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  margin-left: 8px;
  margin-right: -4px;
}

.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  margin: 0;
  padding: 10px;
}

.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe.pSzOP-SxQuSe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  padding: 8px;
}

.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe.purZT-SxQuSe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  padding: 4px;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf {
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #fff;
  height: 36px;
  margin-left: -10px;
  margin-right: 12px;
  min-width: 36px;
  width: 36px;
}

.nsm7Bb-HzV7m-LgbsSe
  .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf
  .nsm7Bb-HzV7m-LgbsSe-Bz112c,
.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe
  .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf
  .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  margin: 0;
  padding: 0;
}

.nsm7Bb-HzV7m-LgbsSe.pSzOP-SxQuSe .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf {
  height: 28px;
  margin-left: -8px;
  margin-right: 10px;
  min-width: 28px;
  width: 28px;
}

.nsm7Bb-HzV7m-LgbsSe.purZT-SxQuSe .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf {
  height: 16px;
  margin-left: -6px;
  margin-right: 8px;
  min-width: 16px;
  width: 16px;
}

.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  margin-left: 2px;
  margin-right: 0;
  padding: 0;
}

.nsm7Bb-HzV7m-LgbsSe.JGcpL-RbRzK .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf {
  -webkit-border-radius: 18px;
  border-radius: 18px;
}

.nsm7Bb-HzV7m-LgbsSe.pSzOP-SxQuSe.JGcpL-RbRzK
  .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf {
  -webkit-border-radius: 14px;
  border-radius: 14px;
}

.nsm7Bb-HzV7m-LgbsSe.purZT-SxQuSe.JGcpL-RbRzK
  .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf {
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  position: relative;
  width: 100%;
}

.nsm7Bb-HzV7m-LgbsSe .oXtfBe-l4eHX {
  justify-content: center;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  flex-grow: 1;
  font-family: "Assistant", arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  border-left: 1px solid gainsboro;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
  padding-left: 8px;
}

.nsm7Bb-HzV7m-LgbsSe.purZT-SxQuSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  font-weight: 300;
}

.nsm7Bb-HzV7m-LgbsSe .oXtfBe-l4eHX .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  -webkit-flex-grow: 0;
  flex-grow: 0;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-MJoBVe {
  -webkit-transition: background-color 0.218s;
  transition: background-color 0.218s;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.nsm7Bb-HzV7m-LgbsSe:hover,
.nsm7Bb-HzV7m-LgbsSe:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #d2e3fc;
  outline: none;
}

.nsm7Bb-HzV7m-LgbsSe:hover .nsm7Bb-HzV7m-LgbsSe-MJoBVe,
.nsm7Bb-HzV7m-LgbsSe:focus .nsm7Bb-HzV7m-LgbsSe-MJoBVe {
  background: rgba(66, 133, 244, 0.04);
}

.nsm7Bb-HzV7m-LgbsSe:active .nsm7Bb-HzV7m-LgbsSe-MJoBVe {
  background: rgba(66, 133, 244, 0.1);
}

.nsm7Bb-HzV7m-LgbsSe.MFS4be-Ia7Qfc:hover .nsm7Bb-HzV7m-LgbsSe-MJoBVe,
.nsm7Bb-HzV7m-LgbsSe.MFS4be-Ia7Qfc:focus .nsm7Bb-HzV7m-LgbsSe-MJoBVe {
  background: rgba(255, 255, 255, 0.24);
}

.nsm7Bb-HzV7m-LgbsSe.MFS4be-Ia7Qfc:active .nsm7Bb-HzV7m-LgbsSe-MJoBVe {
  background: rgba(255, 255, 255, 0.32);
}

.nsm7Bb-HzV7m-LgbsSe .n1UuX-DkfjY {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 20px;
  margin-left: -4px;
  margin-right: 8px;
  min-width: 20px;
  width: 20px;
}

.nsm7Bb-HzV7m-LgbsSe.jVeSEe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  font-family: "Assistant";
  font-size: 12px;
  text-align: left;
}

.nsm7Bb-HzV7m-LgbsSe.jVeSEe .nsm7Bb-HzV7m-LgbsSe-BPrWId .ssJRIf,
.nsm7Bb-HzV7m-LgbsSe.jVeSEe .nsm7Bb-HzV7m-LgbsSe-BPrWId .K4efff .fmcmS {
  overflow: hidden;
  text-overflow: ellipsis;
}

.nsm7Bb-HzV7m-LgbsSe.jVeSEe .nsm7Bb-HzV7m-LgbsSe-BPrWId .K4efff {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #5f6368;
  fill: #5f6368;
  font-size: 11px;
  font-weight: 400;
}

.nsm7Bb-HzV7m-LgbsSe.jVeSEe.MFS4be-Ia7Qfc .nsm7Bb-HzV7m-LgbsSe-BPrWId .K4efff {
  color: #e8eaed;
  fill: #e8eaed;
}

.nsm7Bb-HzV7m-LgbsSe.jVeSEe .nsm7Bb-HzV7m-LgbsSe-BPrWId .K4efff .Bz112c {
  height: 18px;
  margin: -3px -3px -3px 2px;
  min-width: 18px;
  width: 18px;
}

.nsm7Bb-HzV7m-LgbsSe.jVeSEe .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf {
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: 12px;
  margin-right: -10px;
}

.nsm7Bb-HzV7m-LgbsSe.jVeSEe.JGcpL-RbRzK .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf {
  -webkit-border-radius: 18px;
  border-radius: 18px;
}

.rombo-logo > svg > path:nth-child(1) {
  animation: RomboFloat 2s 500ms ease infinite;
  display: block;
}
.rombo-logo > svg > path:nth-child(2) {
  animation: RomboFloat 2s 600ms ease infinite;
  display: block;
}

.rombo-logo > svg > path:nth-child(3) {
  animation: RomboFloat 2s 700ms ease infinite;
  display: block;
}
.rombo-logo > svg > path:nth-child(4) {
  animation: RomboFloat 2s 800ms ease infinite;
  display: block;
}
.rombo-logo > svg > path:nth-child(5) {
  /* transform-origin: left; */
  animation: RomboFloat 2s 900ms ease infinite;
  display: block;
}
