:root {
  --background: #f2f2f2;
  --primary: rgba(212, 218, 38, 0.729);
  --secondary: rgba(247, 253, 84, 0.228);
  --third: rgba(247, 253, 84, 0.1);
}

.blob {
  position: absolute;
  animation: morph 12s ease-in-out infinite;
  background: linear-gradient(45deg, var(--primary) 2%, var(--secondary) 100%);
  border-radius: 90% 40% 60% 70% / 60% 60% 70% 70%;
  box-shadow: 1px 11px 500px var(--third);
  height: 250px;
  transition: all 1s ease-in-out;
  width: 250px;
  z-index: -100;
  opacity: 0.5;
  border: 1px solid var(--secondary);
  outline: 10px solid var(--third);
}
