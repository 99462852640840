.animation-holder {
  border: 1px solid rgb(253 223 66 / 20%);
  box-sizing: border-box;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.animation-information {
  display: flex;
  align-items: center;
}
.animation-element-label {
  background: rgb(253 223 66 / 40%);
  color: #050505;
  font-weight: 600;
  border-radius: 3px;
  height: fit-content;
  margin-right: 1em;
  text-transform: capitalize;
  padding: 0 0.35em;
}
.animation-name {
  /* uppercase; */
  color: #050505;
  font-weight: 600;
  margin: 0em 0.25em;
  text-transform: capitalize;
}
.animation-settings {
  width: 15px;
}
.animation-controller {
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 50px;
  justify-content: center;
}
.animation-holder h2 {
  font-weight: 300;
  font-size: 1.18em;
  margin: 0em;
  margin-right: 0.5em;
}

.animation-switchbox {
  position: relative;
  display: inline-block;
  width: 29px;
  height: 17px;
  margin: 5px 2px;
}

.animation-switchbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

.animation-switchbox-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: 2px solid #e9eef5;
  background-color: rgb(5, 5, 5);
  -webkit-transition: 0.05s;
  transition: 0.05s;
}

.animation-switchbox-slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.2s ease-out;
}
input:checked + .animation-switchbox-slider {
  background-color: #fbd955;
}

input:focus + .animation-switchbox-slider {
  box-shadow: 0 0 1px #fbd955;
}

input:checked + .animation-switchbox-slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.animation-switchbox-slider {
  border-radius: 34px;
}

.animation-switchbox-slider:before {
  border-radius: 50%;
}

.animation-switchbox-off {
  /* transition: 100ms cubic-bezier(0.6, 0.04, 0.98, 0.335); */
  justify-content: flex-end;
}

.animation-settings-icon {
  margin: 0em 0.1em 0em 0.33em;
  cursor: pointer;
}
.animation-settings-icon:hover {
  opacity: 0.66;
}
.animation-controller-dropdown {
  border: 1px solid rgb(255 255 246 / 10%);
  width: 100%;
  position: absolute;
  /* top: -2px; */
  margin-top: 2px;
  left: -2px;
  height: auto;
  z-index: 10;
  border-radius: 15px;
  animation: uppear 300ms ease-out;
  background: #050505;
}
.animation-controller-option {
  color: #fffff6;
  padding: 0.3em 0em 0.3em 0.65em;
  text-transform: capitalize;
  z-index: 1;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 1em;
  z-index: 1;
  display: flex;
  align-items: center;
  transition: 100ms ease-out;
}

.animation-controller-option:first-child {
  border-bottom: 1px solid rgb(255 255 246 / 10%);
}
.animation-controller-container {
  position: relative;
}
.animation-controller-option:hover {
  cursor: pointer;
  color: rgba(255, 255, 246, 0.8);
}
.animation-controller-option:before {
  font-size: 0.66em;
  padding: 0.1em 0.75em 0em 0em;
  font-weight: 600;
  animation: Twist 2000ms ease-out;
}

.animation-controller-option:first-child {
  color: var(--error);
}
.animation-controller-option:nth-child(2) {
  color: var(--primary);
}
.animation-controller-option:first-child:before {
  content: "✕";
}
.animation-controller-option:nth-child(2):before {
  content: "◐";
}
.remove-bold {
  opacity: 0.4;
  text-transform: lowercase;
}
