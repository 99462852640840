.linear-bg {
  background: linear-gradient(
    96.98deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(238, 160, 160, 0.05) 99.07%
  ) !important;
}
.send-email:hover svg {
  animation: emailFloat 4000ms linear forwards;
}

.send-email:focus svg {
  animation: emailFly 1s forwards;
  z-index: 10000;
}

.send-email:focus {
  z-index: 10000;
}
@keyframes emailFloat {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: translate(0, -10px);
    opacity: 1;
  }
  100% {
    transform: translate(0, -20px);
    opacity: 0;
  }
}

@keyframes emailFly {
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1);
    opacity: 1;
  }
  35% {
    transform: translateX(50px) translateY(-30px) rotate(15deg) scale(1);
  }
  85% {
    transform: translateX(80px) translateY(-50px) rotate(-10deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(120px) translateY(-75px) rotate(15deg) scale(0.75);
    opacity: 0;
  }
}
.button-bg {
  background: linear-gradient(270deg, #1a1a1a 0%, rgba(26, 26, 26, 0.88) 100%);
}
