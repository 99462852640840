.animations-holder {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
}
.animate-wave {
  animation: Wave 2s infinite;
}
@keyframes Wave {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px) rotate(10deg);
  }
  55% {
    transform: translateY(-5px) rotate(5deg);
  }
  70% {
    transform: translateY(-5px) rotate(10deg);
  }
  100% {
    transform: translateY(0);
  }
}
