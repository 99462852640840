.receiver-holder {
  display: flex;
  padding: 1em;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    90.91deg,
    rgba(252, 223, 66, 0.2) 2.89%,
    rgba(252, 223, 66, 0.138) 99.52%
  );
  border: 1px solid #fcff6f;
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 25vh;
}

.receiver-holder p {
  font-size: 1em;
  font-weight: 400;
  width: 70%;
}
.receiver-bottom {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.receiver-status {
  display: flex;
  opacity: 0.5;
}
.receiver-icon {
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 1px;
  width: 10px;
  height: 10px;
  background: rgba(118, 238, 118, 0.66);
  display: block;
  border-radius: 50px;
  border: 1px solid rgb(118, 238, 118);
  animation: pulse 1s infinite;
}
.receiver-icon-off {
  background: rgba(238, 118, 118, 0.66);
  border: 1px solid rgb(238, 118, 118);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}
.animator-holder {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  backdrop-filter: blur(2px);
  z-index: 1000;
  left: 0px;
  top: 0px;
}
.animator-window .aw-btn:first-child {
  padding: 2px;
}
.animator-window .aw-btn {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid rgba(5, 5, 5, 0.05);
  background: rgba(5, 5, 5, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  padding: 3px;
  color: rgba(5, 5, 5, 0.66);
  cursor: pointer;
  transition: 300ms ease-in-out;
  filter: saturate(0) !important;
}
.animator-window .aw-btn:nth-child(3) {
  /* transform: rotate(-5deg); */
}
.animator-window .aw-btn:hover {
  opacity: 0.66;
  filter: saturate(1) !important;
  /* transform: rotate(90deg); */
}

.animator-window {
  margin-top: 2.5%;
  width: 95%;
  height: 96%;
  transition: 100ms ease all;
  border-radius: 2px;
  margin-left: 2.5%;
  box-shadow: 0 0 0 5px rgba(55, 55, 58, 0.1);
  background-color: rgba(242, 242, 242, 0.9);
  border: 1px solid rgba(21, 20, 21, 0.1);
  padding: 0 5px;
  backdrop-filter: blur(2px);
  border-radius: 3px;
}
iframe {
  height: 100%;
  width: 100%;
  background: white;
  border-radius: 2px;
}
footer {
  width: calc(100% - 15rem);
  left: 15rem;
}

.delayed-fade-animation {
  animation: 100ms delayed-fade 200ms ease-in-out both;
}

@keyframes delayed-fade {
  0% {
    transform: translateY(50px) scale(0.95);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
}
