.url-changer input {
  font-weight: 600;
  opacity: 0.5;
  text-decoration: underline;
  text-decoration-color: var(--primary);
  text-underline-position: from-font;
  margin-left: 0px;
  font-size: 1em;
  width: max-content;
}
.url-changer-label {
  padding: 0 5px;
}
.url-changer-holder {
  display: inline-flex;
  align-items: baseline;
}

.url-changer-button {
  line-height: 1em;
  cursor: pointer;
  background: rgba(5, 5, 5, 0.1);
  padding: 0.1em 0.2em 0.2em 0.2em;
  border-radius: 5px;
  margin-left: 3px;
}
.url-changer {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 0.1em;
  font-size: 0.8em;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}
