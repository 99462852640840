:root {
  --default: cubic-bezier(0.15, 1, 0.65, 1);
  --stagger: cubic-bezier(0.8, 0.05, 0.5, 0.8);
  --rapid: cubic-bezier(0, 0.73, 0.35, 1);
  --rebound: cubic-bezier(0.07, 0.7, 0.2, 1.2);
  --slow: cubic-bezier(0, 0, 0.25, 0.75);
}
.animations-library-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -0.35em;
}

.animation-preview {
  width: 125px;
  cursor: pointer;
  position: relative;
  color: #050505;
  background: var(--light);
  border: 1px solid var(--primary);
  margin: 0.33em;
  padding: 0.25em 0.5em;
  border-radius: 5px;
}
.animation-preview-title {
  font-size: 1em;
  margin: 0.1em 0;
  line-height: normal;
}
.animation-preview-title sup {
  font-size: 0.66em;
  vertical-align: super;
  text-transform: uppercase;
  opacity: 0.66;
}
.animation-preview-title .icon {
  font-size: 0.8em;
  opacity: 0.66;
  margin: 0 0.25em;
}
.animation-preview-label {
  background: rgba(5, 5, 5, 0.05);
  color: #050505;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 3px;
  padding: 0 0.25em;
  font-size: 0.8em;
  font-weight: 500;
  margin-left: -1px;
  cursor: pointer;
}
.animation-play-icon {
  background: rgb(253 223 66 / 60%);
  width: 24px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 0.66em;
  padding-left: 1px;
}
.animation-preview-load-icon {
  opacity: 0.4;
  padding-left: 3px;
  padding-top: 2px;
}
.animation-preview-controls {
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.previewing-effect {
  color: rgba(5, 5, 5, 0.25);
}
.disallowed {
  cursor: not-allowed;
}
.animation-preview::before {
  content: attr(data-character);
  position: absolute;
  top: 0%;
  right: 20%;
  height: 100%;
  opacity: 0.075;
  font-size: 3em;
}
