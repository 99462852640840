@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Assistant:400,500,600,700");
@import url("https://fonts.googleapis.com/css2?family=Micro+5&display=swap");

.animate-fade {
  animation: Fader infinite 3000ms ease forwards;
}
@keyframes Fader {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.975) translateY(10px) rotate(3deg);
    filter: grayscale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
:root {
  --stagger: cubic-bezier(0, 0.05, 0.1, 0.8);
  --rapid: cubic-bezier(0, 0.73, 0.35, 1);
  --slow: cubic-bezier(0, 0, 0.25, 0.75);
  --primary: #fddf42;
  --light: rgb(253 223 66 / 50%);
  --other: rgba(230, 228, 225, 1);
  --translucent: rgba(251, 251, 255, 0.2);
  --transparent: rgba(251, 251, 255, 0.1);
  --white: #fbfbff;
  --error: #ff5714;
  --errorlite: rgba(255, 87, 20, 0.3);
  --errormid: rgba(255, 87, 20, 0.5);
}
main,
button,
input,
* {
  font-family: "Assistant", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

button {
  font-weight: 600;
}
input {
  font-weight: 500;
  padding-left: 6px;
}
a {
  cursor: pointer;
}
.bendy-bg {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='845' height='101.4' viewBox='0 0 1000 120'><g fill='none' stroke='%23F6F2A1' stroke-width='2.3' stroke-opacity='0.64'><path d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/><path d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/><path d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/><path d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/><path d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/><path d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/></g></svg>");
}
@keyframes uppear {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }

  75% {
    opacity: 1;
    transform: translateY(-1px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes Twist {
  0% {
    transform-origin: top;
    transform: rotate3d(1, 0, 0, 73deg);
  }
  30% {
    transform-origin: top;
    transform: rotate3d(1, 0, 0, -20deg);
  }
  70% {
    transform: rotate3d(1, 0, 0, 10deg);
  }
  100% {
    transform-origin: top;
    transform: rotate3d(1, 0, 0, 0deg);
  }
}
@keyframes RomboFloat {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateY(5%) rotate(2deg);
  }
  50% {
    transform: translateY(-2.5%) rotate(-2deg);
  }
  100% {
    transform: translateX(0%);
  }
}
.fade-in {
  animation: fadeIn 1000ms 400ms cubic-bezier(0, 0.2, 0.2, 1) both;
}

.finger-point {
  position: relative;
}

.finger-point:before {
  content: "👉";
  animation: fingerPointer1 3000ms infinite cubic-bezier(0, 0.2, 0.2, 1) both;
  position: absolute;
  top: -35px;
  right: 55px;
  z-index: 1000;
}

@keyframes fingerPointer1 {
  0% {
    transform: translateY(30px) rotate(60deg);
  }
  30% {
    transform: rotate(50deg);
  }
  40% {
    transform: rotate(55deg);
  }
  50% {
    transform: rotate(45deg);
  }
  60% {
    transform: rotate(40deg);
  }
  100% {
    transform: translateY(30px) rotate(25deg);
  }
}

.modal-transition {
  animation: FadeIn 350ms ease both;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
